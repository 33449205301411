import {
  get,
  put,
  post,
  postXtoken,
  putXtoken,
  getMp,
  getMp2,
  postMp,
  getBanner,
  postYUN,
  getYUN,
  getYUNByid,
} from "./base_fire";

/**
 * 获取首页banner图  //旧（废弃)
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function geBannerList2(data) {
  return get("/api/v1/app/home/banners", data);
}

/**
 * 获取首页banner图  //新
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function geBannerList3(data) {
  return getBanner("/user/banner/recommend", data);
}

export function geLiveList() {
  // return post('/api2/v1/app/live-meet/conventionAndLiveMettingList',{domain_code,column_code:'YUN_ICU',member_id},data)
  return getYUN("/user/liveConvention/convention");
}

/**
 * 手机号密码登录
 * @returns {Promise<unknown | void>}
 * @param os
 * @param phone
 * @param type
 * @param password
 */
export function postUserNamePasswordLogin(data) {
  return postYUN("/user/login/password",data);
}

/**
 * 获取省份
 * @returns {Promise<unknown | void>}
 */
export function getProvinces() {
  return getMp("/api/v1/app/common/provinces");
}

/**
 * 获取城市
 * @returns {Promise<unknown | void>}
 */
export function getProvince(string) {
  return getMp("/api/v1/app/common/cities?province=" + string);
}

/**
 * 获取医院
 * @returns {Promise<unknown | void>}
 */
export function getHospitals(province, city) {
  return getMp(
    "/api/v1/app/common/hospitals?province=" + province + "&city=" + city
  );
}

/**
 * 获取学院
 * @returns {Promise<unknown | void>}
 */
export function getSchool(province, city) {
  return getMp(
    "/api/v1/app/auth/schools?province=" + province + "&city=" + city
  );
}

/**
 * 获取科室
 * @returns {Promise<unknown | void>}
 */
export function getDepartments() {
  return getMp("/api/v1/app/common/departments");
}

/**
 * 获取职称
 * @returns {Promise<unknown | void>}
 */
export function getTitles() {
  return getMp("/api/v1/app/common/titles");
}

/**
 * 修改个人资料
 * @returns {Promise<unknown | void>}
 */
export function putMyInfo(data) {
  return putXtoken("/api/v1/app/my/info", data);
}

/**
 * 获取个人资料
 * @returns {Promise<unknown | void>}
 */
export function getPersonalData() {
    //   return getMp("/api/v1/app/my/info");
    return getYUNByid('/user/member/info/byId')
}

/**
 * 验证码登录-获取验证码
 * @returns {Promise<unknown | void>}
 */
export function postLoginPhoneCode(tel, type) {
  return postYUN(`/user/sms/login/send?tel=${tel}&type=${type}`);
}
//   export function postLoginPhoneCode (data) {
//       // return postMp('/api/v1/app/common/send-sms-code', data)
//       return postMp('/api/v1/app/common/yunicu_auth', data)
//   }

/**
 * 验证码登录-登录
 * @returns {Promise<unknown | void>}
 */
export function postLoginPhoneCodeSubmit(type, data) {
    return postYUN(`/user/sms/login?type=${type}`,data);
        
   }
// export function postLoginPhoneCodeSubmit (data) {
//     return postMp('/api/v1/app/common/live-h5-login', data)
// }
// export function postLoginPhoneCodeSubmit(data) {
//   //新
//   return postMp("/api/v1/app/phone_code_login", data);
// }
/**
 * 校验短信验证码
 * */
export function PhoneCode(type,data) {
  return postYUN(`/user/sms/forgetPassword/code?type=${type}`, data);
}
/**
 * 重置密码
 * */
export function ResetPassword(type,data) {
  return postYUN(`/user/sms/forgetPassword?type=${type}`, data);
}
// 忘记密码 - 发送验证码;
export function forgetCode(tel, type) {
       return postYUN(`/user/sms/forgetPassword/send?tel=${tel}&type=${type}`,);
}
// 修改密码
export function editPassword(type, data) {
      return postYUN(`/user/sms/forgetPassword/noVerification?type=${type}`,data)
}